import React from "react";

const Text = () => {
  return (
    <div className="voice-chat-txt">
        <span>Speak to our Voice AI Assistant just like you're talking to a real person.</span>
    </div>
  );
};

export default Text;