import React, { useEffect } from 'react';

const SoundWave = ({ isSpeaking }) => {
  useEffect(() => {
    if (isSpeaking) {
      startAnimations();
    } else {
      stopAnimations();
    }
  }, [isSpeaking]);

  const startAnimations = () => {
    document.querySelectorAll('.bar-scale1, .bar-scale2, .bar-scale3').forEach(el => {
      el.classList.remove('stop-animation');
    });
  };

  const stopAnimations = () => {
    document.querySelectorAll('.bar-scale1, .bar-scale2, .bar-scale3').forEach(el => {
      el.classList.add('stop-animation');
    });
  };

  return (
    <div className="sound-wave">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 560" preserveAspectRatio="none">
        <g mask="url(#SvgjsMask1099)" fill="none">
          <rect fill="" width="1440" height="560"></rect>
          <g transform="translate(0, 0)" strokeLinecap="round" stroke="url(#SvgjsLinearGradient1100)">
            <path d="M375 202.15 L375 357.85" strokeWidth="17.25" className="bar-scale2 stop-animation"></path>
            {/* <path d="M398 155.33 L398 404.67" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M421 196.44 L421 363.56" strokeWidth="17.25" className="bar-scale3 stop-animation"></path>
            {/* <path d="M444 259.91 L444 300.09" strokeWidth="17.25" className="bar-scale1 stop-animation"></path> */}
            <path d="M467 208.25 L467 351.75" strokeWidth="17.25" className="bar-scale3 stop-animation"></path>
            {/* <path d="M490 184.8 L490 375.2" strokeWidth="17.25" className="bar-scale2 stop-animation"></path> */}
            <path d="M513 249.28 L513 310.72" strokeWidth="17.25" className="bar-scale2 stop-animation"></path>
            {/* <path d="M536 220.75 L536 339.25" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M559 254.8 L559 305.2" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M582 186.77 L582 373.23" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M605 210.13 L605 349.87" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M628 234.45 L628 325.55" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M651 241.1 L651 318.89" strokeWidth="17.25" className="bar-scale2 stop-animation"></path>
            {/* <path d="M674 202.95 L674 357.05" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M697 165.81 L697 394.19" strokeWidth="17.25" className="bar-scale2 stop-animation"></path>
            {/* <path d="M720 224.51 L720 335.49" strokeWidth="17.25" className="bar-scale2 stop-animation"></path> */}
            <path d="M743 157.59 L743 402.4" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M766 164.98 L766 395.02" strokeWidth="17.25" className="bar-scale1 stop-animation"></path> */}
            <path d="M789 158.93 L789 401.07" strokeWidth="17.25" className="bar-scale3 stop-animation"></path>
            {/* <path d="M812 224.24 L812 335.76" strokeWidth="17.25" className="bar-scale2 stop-animation"></path> */}
            <path d="M835 171.73 L835 388.27" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M858 264.89 L858 295.11" strokeWidth="17.25" className="bar-scale2 stop-animation"></path> */}
            <path d="M881 175.14 L881 384.86" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M904 248.17 L904 311.83" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M927 185.4 L927 374.6" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M950 234.82 L950 325.18" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M973 229.9 L973 330.1" strokeWidth="17.25" className="bar-scale3 stop-animation"></path>
            {/* <path d="M996 194.25 L996 365.75" strokeWidth="17.25" className="bar-scale2 stop-animation"></path> */}
            <path d="M1019 162.47 L1019 397.53" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
            {/* <path d="M1042 205.06 L1042 354.94" strokeWidth="17.25" className="bar-scale3 stop-animation"></path> */}
            <path d="M1065 240.52 L1065 319.48" strokeWidth="17.25" className="bar-scale1 stop-animation"></path>
          </g>
        </g>
        <defs>
          <mask id="SvgjsMask1099">
            <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
          <linearGradient x1="360" y1="280" x2="1080" y2="280" id="SvgjsLinearGradient1100">
            <stop stopColor="#3a7cc3" offset="0"></stop>
            <stop stopColor="#dd1133" offset="1"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default SoundWave;