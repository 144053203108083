// import ScaleLoader from "react-spinners/ScaleLoader";
// import voice from "../../Images/voice.png";
import React from "react";
import Text from "../Text";
const Button = ({ label, onClick, isLoading, disabled, assistantIsSpeaking, isMuted, onUserSpeak, onUserMicMute }) => {
  const opacity = disabled ? 0.75 : 1;
  const cursor = disabled ? "not-allowed" : "pointer";
 
  return (
    <div>
      {( isLoading  || !assistantIsSpeaking ) &&
      <Text/>
    }
    <div className="voice-p-wrap">
      <button
        className="voice-img-btn"
        style={{ opacity, cursor }}
        onClick={assistantIsSpeaking ? ()=>{} : onClick}
        disabled={disabled}
      >
        {isLoading ? 
          // <ScaleLoader className="loader-common"
          //   color="#fff"
          //   height={10}
          //   width={2.5}
          //   margin={0.5}
          //   loading={true}
          //   size={50}
          //   css={{ display: "block", margin: "0 auto" }}
          // />
          <div className="animatied-loader-p-wrap">
            <div className="animated-circle main-page">
              <div className="opacity-animation">
                <div className="boun-box">

                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9998 25.3333V28M15.9998 25.3333C11.0955 25.3333 8.19552 22.3268 6.68555 20M15.9998 25.3333C20.9041 25.3333 23.804 22.3268 25.314 20M21.3331 9.33333V14.6667C21.3331 17.6122 18.9453 20 15.9998 20C13.0543 20 10.6664 17.6122 10.6664 14.6667V9.33333C10.6664 6.38781 13.0543 4 15.9998 4C18.9453 4 21.3331 6.38781 21.3331 9.33333Z" stroke="#94FFD2" stroke-width="2" stroke-linecap="square"></path></svg>
                  <div className="circle-backdrop"></div>
                </div>
              </div>
            </div>
            <span className="call-label">One sec...</span>
          </div>
       :
       (!assistantIsSpeaking &&
          <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
          <div className="animated-circle main-page">
            <div className="bounce-animation">
              <div className="boun-box">
               
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9998 25.3333V28M15.9998 25.3333C11.0955 25.3333 8.19552 22.3268 6.68555 20M15.9998 25.3333C20.9041 25.3333 23.804 22.3268 25.314 20M21.3331 9.33333V14.6667C21.3331 17.6122 18.9453 20 15.9998 20C13.0543 20 10.6664 17.6122 10.6664 14.6667V9.33333C10.6664 6.38781 13.0543 4 15.9998 4C18.9453 4 21.3331 6.38781 21.3331 9.33333Z" stroke="#94FFD2" stroke-width="2" stroke-linecap="square"></path></svg>
          <div className="circle-backdrop"></div>
          </div>
          </div>
          </div>
       
        {!isLoading && <span className="call-label">{label}</span>}
        </div>
       )
         }
        {( assistantIsSpeaking ) &&
<>


        <div onClick={isMuted ? onUserSpeak : onUserMicMute}  style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
         <div className={`animated-circle push-to-talk-ani-p ${isMuted ? "inactive" :'active' }`}>
         <div className="push-to-talk-ani">
           <div className="boun-box">
               {
                  isMuted ?
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="32px" width="32px" xmlns="http://www.w3.org/2000/svg"><path d="M16.4249 17.839C15.3929 18.4228 14.2341 18.8087 13.0002 18.9451V23H11.0002V18.9451C6.82854 18.4839 3.51623 15.1716 3.05509 11H5.07105C5.55627 14.3923 8.47368 17 12.0002 17C13.0503 17 14.0464 16.7688 14.9404 16.3544L13.3902 14.8042C12.9489 14.9317 12.4825 15 12.0002 15C9.23873 15 7.00016 12.7614 7.00016 10V8.41421L1.39355 2.80761L2.80777 1.3934L22.6068 21.1924L21.1925 22.6066L16.4249 17.839ZM19.3747 15.1604L17.9323 13.7179C18.4407 12.9084 18.788 11.9874 18.9293 11H20.9452C20.7754 12.5366 20.2187 13.9565 19.3747 15.1604ZM16.4658 12.2514L7.68575 3.47139C8.55427 1.99268 10.1613 1 12.0002 1C14.7616 1 17.0002 3.23858 17.0002 6V10C17.0002 10.8099 16.8076 11.5748 16.4658 12.2514Z"></path></svg>
                  :
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9998 25.3333V28M15.9998 25.3333C11.0955 25.3333 8.19552 22.3268 6.68555 20M15.9998 25.3333C20.9041 25.3333 23.804 22.3268 25.314 20M21.3331 9.33333V14.6667C21.3331 17.6122 18.9453 20 15.9998 20C13.0543 20 10.6664 17.6122 10.6664 14.6667V9.33333C10.6664 6.38781 13.0543 4 15.9998 4C18.9453 4 21.3331 6.38781 21.3331 9.33333Z" stroke="#94FFD2" stroke-width="2" stroke-linecap="square"></path></svg>
               }
             <div className="circle-backdrop"></div>
           </div>
         </div>
       </div>
        <span className="call-label">{isMuted ? "Tap to unmute" : "Tap to mute"}</span>
        </div>
            <div onClick={onClick} className="end-call-animation-btn-p">
              <div className="animated-circle">
                <div className="end-call-animation-btn">
                  <div className="boun-box">

                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M961.696 199.552c0 1.056-.097 1.935-.225 2.623-26.16 18-172.433 114.624-199.776 132.16-2.88.065-10.191-.911-20.623-6.4-11.12-5.84-43.536-24.032-88.88-49.904l-35.28-20.128-33.248 23.344c-24.72 17.408-78.464 58.817-160.288 140.624-82.176 82.16-123.456 135.712-140.768 160.336l-23.344 33.248 20.16 35.28c19.537 34.193 42.945 75.504 50 88.945 5.68 10.784 6.129 18.16 6.129 20.16 0 .32 0 .593-.033.816-15.36 24.497-114.593 173.937-132.673 200.32-2.56.432-8.128.032-15.088-4.816-56.256-40.608-114.96-98.24-123.376-120.8 5.632-120.032 111.12-288.464 297.568-474.88 186.464-186.4 354.72-291.872 474.352-297.44 22.624 8.096 80.624 66.815 120.912 122.527 2.832 4.128 4.48 9.232 4.481 13.985zm62.004-.001c0-16.944-5.121-34.914-15.969-50.498-1.055-1.504-108.256-152.096-170.336-150.096-174.432 5.552-379.439 175.056-520.703 316.271C175.46 456.444 5.892 661.452.307 836.572v1.44c0 61.312 148.672 169.088 150.144 170.128 40.4 28.289 84.881 17.968 102.945-7.776 11.008-15.664 124.976-187.056 137.808-208.063 5.6-9.152 8.336-20.32 8.336-32.464 0-15.664-4.576-33.008-13.473-49.935-8.687-16.496-37.119-66.464-51.086-90.912 15.12-21.537 53.872-72.128 133.664-151.84 79.183-79.216 130.19-118.32 151.84-133.535 24.431 13.935 74.399 42.335 90.847 50.975 31.008 16.368 61.968 18.225 82.848 4.945 19.68-12.464 189.808-125.968 206-137.68 15.28-11.056 23.52-30.848 23.52-52.304z"></path></svg>
                    <div className="circle-backdrop"></div>
                  </div>
                </div>
              </div>

              {!isLoading && <span className="call-label">{label}</span>}
            </div>
        </>
        }
     
      </button>
    </div>
    </div>
  );

};

export default Button;